import React from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { ReactComponent as DCHIconWhite } from "@images/icons/dch-logo-white.svg"
import classnames from "classnames"

import "./style.scss"

class CustomImage extends React.Component {
  constructor() {
    super()

    this.state = {
      dpi: (typeof window !== "undefined" && window.devicePixelRatio) || 1,
    }

    this.source = this.source.bind(this)
  }

  componentDidMount() {
    this.setState({
      dpi: (typeof window !== "undefined" && window.devicePixelRatio) || 1,
    })
  }

  source(width) {
    if (!this.props.src) return ""
    const url = this.props.src.split("?")[0]
    const search = this.props.src.split("?")[1]
    const parsed = queryString.parse(search) || {}
    parsed.w = width
    delete parsed.h
    parsed.dpr = this.state.dpi

    return `${url}?${queryString.stringify(parsed)}`
  }

  render() {
    if (this.props.src) {
      return (
        <img
          data-test-id="custom-image"
          className={this.props.className}
          srcSet={`
          ${this.source(576)} 576w,
          ${this.source(992)} 992w,
          ${this.source(this.props.width)}
        
        `}
          sizes="100vw"
          src={this.source(this.props.width)}
          alt={this.props.alt}
        />
      )
    } else {
      return (
        <div
          className={classnames(
            "custom-image__placeholder",
            this.props.className
          )}
        >
          <DCHIconWhite />
        </div>
      )
    }
  }
}

CustomImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number, // the width to tell Imgix to fetch
  format: PropTypes.string, // the format to tell Imgix to fetch
}

CustomImage.defaultProps = {
  format: "jpeg",
  width: 800,
}

export default CustomImage
